<template>
  <form
    class="flex justify-between p-0 form-input"
    @submit.prevent="search()"
  >
    <div class="relative flex w-full h-full">
      <input
        v-model="query"
        type="text"
        name="query"
        class="relative flex-1 w-full h-full p-2 m-0 mr-2 focus:outline-none"
        :placeholder="placeholder"
        @keyup="showClearSearch = true"
      >
      <div class="ml-4">
        <div
          v-if="showClearSearch"
          class="absolute inset-y-0 right-0 flex flex-col items-center justify-center mx-2"
          @click="clearSearch"
        >
          <Icon
            view-box="0 0 24 24"
            class="w-4 h-4 text-gray-500 transform rotate-45 cursor-pointer"
          >
            <Plus />
          </Icon>
        </div>
      </div>
    </div>
    <button
      class="inline-block px-2 py-1 m-0 font-medium leading-tight text-gray-700 duration-150 bg-gray-200 focus:outline-none hover:text-gray-900"
      @onClick="search()"
    >
      Search
    </button>
  </form>
</template>

<script>
import Icon from '@components/Icons/Icon'
import Plus from '@components/Icons/Plus'

export default {
  components: {
    Icon,
    Plus
  },

  props: {
    searchQuery: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      query: '',
      showClearSearch: false
    }
  },

  mounted() {
    this.query = this.searchQuery
  },

  methods: {
    search() {
      this.$emit('search', this.query)
    },

    clearSearch() {
      this.query = ''
      this.showClearSearch = false
      this.search()
    }
  }
}
</script>

<template>
  <ul class="flex justify-center items-center py-6">
    <li class="px-2">
      <button
        type="button"
        :class="['focus:outline-none', isInFirstPage ? 'text-gray-300 cursor-default' : 'text-gray-600']"
        :disabled="isInFirstPage"
        aria-label="Go to first page"
        @click="onClickFirstPage"
      >
        <Icon
          width="12px"
          height="12px"
          view-box="0 0 284.929 284.929"
        >
          <Chevron double />
        </Icon>
      </button>
    </li>

    <li class="px-2 pr-6">
      <button
        type="button"
        :class="['focus:outline-none', isInFirstPage ? 'text-gray-300 cursor-default' : 'text-gray-600']"
        :disabled="isInFirstPage"
        aria-label="Go to previous page"
        @click="onClickPreviousPage"
      >
        <Icon
          width="12px"
          height="12px"
          view-box="0 0 284.929 284.929"
        >
          <Chevron />
        </Icon>
      </button>
    </li>

    <li
      v-for="(page, index) in pages"
      :key="index"
      class="px-2 text-sm"
    >
      <button
        type="button"
        :disabled="page.isDisabled"
        :class="['focus:outline-none bg-gray-300 py-1 px-2 leading-none', {'bg-secondary text-white': isPageActive(page.name) }]"
        :aria-label="`Go to page number ${page.name}`"
        @click="onClickPage(page.name)"
      >
        {{ page.name }}
      </button>
    </li>

    <li class="px-2 pl-6">
      <button
        type="button"
        :class="['focus:outline-none', isInLastPage ? 'text-gray-300 cursor-default' : 'text-gray-600']"
        :disabled="isInLastPage"
        aria-label="Go to next page"
        @click="onClickNextPage"
      >
        <Icon
          width="12px"
          height="12px"
          view-box="0 0 284.929 284.929"
          class="transform rotate-180"
        >
          <Chevron />
        </Icon>
      </button>
    </li>

    <li class="px-2">
      <button
        type="button"
        :class="['focus:outline-none', isInLastPage ? 'text-gray-300 cursor-default' : 'text-gray-600']"
        :disabled="isInLastPage"
        aria-label="Go to last page"
        @click="onClickLastPage"
      >
        <Icon
          width="12px"
          height="12px"
          view-box="0 0 284.929 284.929"
          class="transform rotate-180"
        >
          <Chevron double />
        </Icon>
      </button>
    </li>
  </ul>
</template>

<script>
import Icon from '@components/Icons/Icon'
import Chevron from '@components/Icons/Chevron'

export default {
  components: {
    Icon,
    Chevron
  },

  props: {
    maxVisibleButtons: {
      type: Number,
      default: 3
    },
    totalPages: {
      type: Number,
      default: 0
    },
    perPage: {
      type: Number,
      default: 0
    },
    currentPage: {
      type: Number,
      default: 0
    }
  },

  computed: {
    /**
     * @return {Number}
     */
    startPage() {
      if (this.currentPage === 1) {
        return 1
      }

      if (this.currentPage === this.totalPages) {
        if (this.maxVisibleButtons > this.totalPages) {
          // If we only have 1 or 2 pages, the start page is page 1
          return 1
        }
        return this.totalPages - this.maxVisibleButtons + 1
      }

      return this.currentPage - 1
    },

    /**
     * @return {Number}
     */
    endPage() {
      return Math.min(this.startPage + this.maxVisibleButtons - 1, this.totalPages)
    },

    /**
     * @return {Array}
     */
    pages() {
      const range = []
      for (let i = this.startPage; i <= this.endPage; i += 1) {
        range.push({
          name: i,
          isDisabled: i === this.currentPage
        })
      }
      return range
    },

    /**
     * @return {Boolean}
     */
    isInFirstPage() {
      return this.currentPage === 1
    },

    /**
     * @return {Boolean}
     */
    isInLastPage() {
      return this.currentPage === this.totalPages
    }
  },

  methods: {
    onClickFirstPage() {
      this.$emit('pagechanged', 1)
    },

    onClickPreviousPage() {
      this.$emit('pagechanged', this.currentPage - 1)
    },

    onClickPage(page) {
      this.$emit('pagechanged', page)
    },

    onClickNextPage() {
      this.$emit('pagechanged', this.currentPage + 1)
    },

    onClickLastPage() {
      this.$emit('pagechanged', this.totalPages)
    },

    isPageActive(page) {
      return this.currentPage === page
    }
  }
}
</script>

<template>
  <router-link
    :to="candidateProfileLink"
  >
    <slot />
  </router-link>
</template>

<script>
export default {
  props: {
    candidateId: {
      type: String,
      default: null
    },
    jobId: {
      type: String,
      default: null
    }
  },

  computed: {
    candidateProfileLink() {
      if (!this.candidateId) {
        return {}
      }

      if (this.$usesFeature('classic-jobs')) {
        if (!this.jobId) {
          return {
            name: 'candidate-profile',
            params: {
              id: this.candidateId
            }
          }
        }
        return {
          name: 'client-assessments-candidate',
          params: {
            id: this.jobId,
            candidate: this.candidateId
          }
        }
      }

      if (this.$usesFeature('candidate-wizard')) {
        return {
          name: 'candidate-profile-enhanced', params: { id: this.candidateId }
        }
      }
    }
  }
}
</script>
